.auth-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
    background-image: url('/public/assets/ship.png');
    background-size: cover;
    background-position: center;
}


.login-message {
    font-size: 2rem;
  
    padding: 20px;

    margin-top: -200px; 
}

.photo-credit {
    position: absolute;
    bottom: 1px;
    left: 20px;
    font-size: 10px;
    font-family: "Courier New", monospace; /* Fancier font */
    font-weight: bold;
    color: black;
    padding: 5px 10px;

}