/* sliding bar styling  */
.slider-container {
    display: flex;
    
    align-items: center;
    gap: 10px;
    background: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    position: absolute;
     bottom: 20px;
    /* bottom: 15%; */
    left: 20px;
    width:50%;
    z-index: 1000;
  }
  
  .play-button {
    background: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .slider {
    width: 80%;
  }
  
  .band-label {
    text-align: center;
    font-size: 12px;
  }

  /* Note (Forecast Model) styling */
  
  .notice {
    background: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
     width: fit-content;

    max-width: 90%;
  }

  /* time stamp box styling */
  .band-label {
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: rgba(0, 0, 0, 0.6); /* Slight transparency */
    padding: 8px 12px;
    border-radius: 5px;
    position: absolute;
    bottom: 100%;
    right: 0px;
    margin-bottom: 10px;
    z-index: 999;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  }

  /* colour bar styling */
  
  .slider-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .color-gradient-container{
    width: 15%;
    height: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin-bottom: 10px;
    border-radius: 5px;
    z-index:1000;

    bottom: 20%;
    left:20px;
  }
  
  .color-gradient-bar {
    width: 90%;
    height: 10px;
    position: absolute;
    background: linear-gradient(to right, #ffffff, #ffcccc, #990000);
    margin-bottom: 10px;
    border-radius: 5px;
    z-index:1000;
    bottom: 20%;
    left:20px;
  }
 
  .gradient-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: black;
}

.gradient-label-left {
  text-align: left;
  width: 20px;
}

.gradient-label-right {
  text-align: right;
  width: 20px; 
}